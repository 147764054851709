import React from 'react'
import Homepage from '../HomePage/Homepage'

const Home = () => {
  return (
    <>
    <Homepage/>
    </>
  )
}

export default Home
